import { useEffect, useState } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useCookies } from "react-cookie";

export const useCookieConsent = () => {
  const [cookies] = useCookies(["cc_cookie"]);
  const ccConsent = cookies["cc_cookie"];

  const consentInitial = {
    necessary: false,
    marketing: false,
    analytics: false,
    preferences: false,
  };

  const consentCookies = ccConsent?.categories.reduce((item, category) => {
    item[category] = true;
    return item;
  }, {});

  const [consent, setConsent] = useState(consentCookies ? consentCookies : consentInitial);

  useEffect(() => {
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box wide",
          position: "bottom left",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        functionality: {},
        analytics: {},
        marketing: {},
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "Dostosowujemy się do Ciebie",
              description:
                "Używamy ciasteczek w celu zapewnienia prawidłowego funkcjonowania serwisu. Możemy również wykorzystywać pliki cookie własne oraz naszych partnerów w celach analitycznych i marketingowych, w szczególności dopasowania treści reklamowych do Twoich preferencji.",
              acceptAllBtn: "Akceptuj wszystkie",

              showPreferencesBtn: "Ustawienia",
              footer:
                '<a target="_blank" href="https://gleevery.com/polityka-prywatnosci">Polityka prywatności</a>\n<a target="_blank"  href="https://files.gleevery.com/Regulamin-wynajmu-dla-klienta-Gleevery.pdf">Regulamin</a>',
            },
            preferencesModal: {
              title: "Ustawienia ciasteczek",
              acceptAllBtn: "Akceptuj wszystkie",

              savePreferencesBtn: "Zapisz",
              closeIconLabel: "Zamknij",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Ustawienia ciasteczek",
                  description:
                    "Poniżej możesz sprawdzić, jakie dane zbieramy w ciasteczkach i po co je zbieramy. Nie na wszystkie musisz się zgodzić. Zawsze możesz zmienić swój wybór na stronie ciasteczek.",
                },
                {
                  title: 'Ciasteczka, dzięki którym działa nasza strona <span class="pm__badge">Zawsze włączone</span>',
                  description:
                    "Korzystanie przez nas z niezbędnych plików cookie jest konieczne dla prawidłowego funkcjonowania strony internetowej. Pliki te są instalowane w szczególności na potrzeby zapamiętywania sesji logowania lub wypełniania formularzy, a także w celach związanych ustawieniem opcji prywatności.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Funkcjonalne pliki cookie",
                  description:
                    "Funkcjonalne pliki cookie zapamiętują i dostosowują stronę do Twoich wyborów, np. preferencji językowych. Możesz ustawić przeglądarkę tak, aby blokowała lub ostrzegała o niezbędnych i funkcjonalnych plikach cookie, jednak spowoduje to, że niektóre części witryny mogą nie działać prawidłowo.",
                  linkedCategory: "functionality",
                },
                {
                  title: "Analityczne pliki cookie",
                  description:
                    "Analityczne pliki cookie umożliwiają sprawdzenie liczby wizyt i źródeł ruchu w naszym serwisie. Pomagają nam ustalić, które strony są bardziej, a które mniej popularne, i zrozumieć, jak użytkownicy poruszają się po stronie. Dzięki temu możemy badać statystki i poprawiać wydajność naszej witryny. Informacje zbierane przez te pliki cookie są agregowane, nie mają więc na celu ustalenia Twojej tożsamości. Jeśli nie zezwolisz na stosowanie tych plików cookie, nie będziemy wiedzieć, kiedy odwiedziłeś naszą stronę internetową.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Reklamowe pliki cookie",
                  description:
                    "Pliki cookies pozwalające na wyświetlanie na innych stronach WWW reklam, które zostaną dopasowane do Twoich zainteresowań. Ciasteczka umożliwiają nam analizę Twoich preferencji i promocję tych produktów, które faktycznie mogą Ciebie zainteresować. Prowadząc działania reklamowe korzystamy z rozwiązań pochodzących od autoryzowanych partnerów zewnętrznych. Uzyskują oni dostęp do zebranych informacji i danych dotyczących Twoich preferencji, które mogą także przetwarzać na swoje własne potrzeby.",
                  linkedCategory: "marketing",
                },
              ],
            },
          },
        },
      },
      onConsent: function (categoriesData) {
        if (categoriesData && categoriesData.cookie.categories) {
          const categories = categoriesData.cookie.categories;
          const updatedConsent = {};

          categories.forEach((category) => {
            updatedConsent[category] = true;
          });
          setConsent(updatedConsent);
        }
      },
      cookie: {
        domain: getRootDomain(),
      },
    });
    document.documentElement.classList.add("cc-theme-gleevery");
  }, []);

  return consent;
};

function getRootDomain() {
  let hostname = window.location.hostname;
  const hostnameExtracted = hostname.split(".");
  let domain = hostname;

  if (hostnameExtracted.length > 1) {
    domain = hostnameExtracted.slice(-2).join(".");
  }

  return domain;
}
