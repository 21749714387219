import Head from "next/head";

import "./../styles/globals.scss";

import { CookiesProvider } from "react-cookie";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./../src/apollo-client";
import GoogleTagManagerProvider from "src/common/components/GoogleTagManagerProvider";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <CookiesProvider>
        <ApolloProvider client={apolloClient}>
          <GoogleTagManagerProvider>
            <Component {...pageProps} />
          </GoogleTagManagerProvider>
        </ApolloProvider>
      </CookiesProvider>
    </>
  );
}

export default MyApp;
