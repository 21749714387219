import React, { useEffect } from "react";
import { GoogleTagManager } from "@next/third-parties/google";
import { useCookieConsent } from "src/content/components/cookies/useCookieConsent";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

const GoogleTagManagerProvider = ({ children }) => {
  const googleTagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;
  const consent = useCookieConsent();

  useEffect(() => {
    if (consent) {
      gtag("consent", "default", {
        functionality_storage: consent.necessary ? "granted" : "denied",
        security_storage: consent.necessary ? "granted" : "denied",
        ad_storage: consent.marketing ? "granted" : "denied",
        ad_personalization: consent.marketing ? "granted" : "denied",
        ad_user_data: consent.marketing ? "granted" : "denied",
        analytics_storage: consent.analytics ? "granted" : "denied",
        personalization: consent.preferences ? "granted" : "denied",
      });
    }
  }, []);

  useEffect(() => {
    if (consent) {
      gtag("consent", "update", {
        functionality_storage: consent.necessary ? "granted" : "denied",
        security_storage: consent.necessary ? "granted" : "denied",
        ad_storage: consent.marketing ? "granted" : "denied",
        ad_personalization: consent.marketing ? "granted" : "denied",
        ad_user_data: consent.marketing ? "granted" : "denied",
        analytics_storage: consent.analytics ? "granted" : "denied",
        personalization: consent.preferences ? "granted" : "denied",
      });
    }
  }, [consent]);

  return (
    <>
      {children}
      {isObject(consent) && isString(googleTagId) && !isEmpty(googleTagId) && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_ID} />
      )}
    </>
  );
};

function gtag() {
  if (typeof window === "undefined") {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export default GoogleTagManagerProvider;
